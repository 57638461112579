<template>
  <div></div>
</template>

<script>
import dd from 'dingtalk-jsapi'
import axios from 'axios'
import { Dialog } from 'vant';
export default {
  name: "auto",
  data() {
    return {
      open_id: ''
    }
  },
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  props: ['router','activity_id'],
  created() {
    this.init()
  },
  mounted() {
    
  },
  methods: {
    init() {
      const _this = this;
      if (dd.env.platform !== 'notInDingTalk') {
        dd.ready(function () {
          dd.runtime.permission.requestAuthCode({
            corpId: 'ding45de1b84a537709435c2f4657eb6378f', // 企业id
            onSuccess: function (info) {
              let code = info.code // 通过该免登授权码可以获取用户身份
              const params = {}
              params.code = code
              axios.get('https://resapi.121314.com/dingding/code', {
                params
              })
              .then(function (res) {
                let data = res.data;
                if (data.code == 200) {
                  const result = data.result
                  localStorage.setItem('userInfo', JSON.stringify(result))
                  if (_this.router == 1) {
                    _this.$router.replace({ name: 'birthday', query: { activity_id: _this.activity_id }})
                  } else {
                    _this.$router.replace({ name: 'Index' })
                  }
                } else {
                  _this.$router.replace({ name: 'Error' })
                }
              })
              .catch(function (error) {
                console.log(error)
                Dialog.alert({
                  title: '请求报错',
                  message: JSON.stringify(error),
                }).then(() => {
                  // on close
                });
                _this.$router.replace({ name: 'Error' })
              });
            },
            onFail: function (err) {
              console.log(err)
              Dialog.alert({
                title: '请求失败',
                message: JSON.stringify(err),
              }).then(() => {
                // on close
              });
              _this.$router.replace({ name: 'Error' })
            }
          });
        });
      }else {
        Dialog.alert({
          title: '请在钉钉客户端打开',
        }).then(() => {
          // on close
        });
        _this.$router.replace({ name: 'Error' })
      }
    }
  }
}
</script>

<style scoped>

</style>